import React from 'react';
import './CreateSuccess.css';
import {Button, Col, Form, Row} from "react-bootstrap";
import {Check} from "react-bootstrap-icons";
import axios from "axios";
import {Link} from "react-router-dom";

class CreateSuccess extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="create-success-container">
                    <div className="registered-row">
                        <Check color="green" size={84}></Check>
                        <h2>You're registered!</h2>
                    </div>
                </div>
                <div className="registered-row">
                    <Link to="/login">Back to Login</Link>
                </div>
            </div>
        );
    }
}

export default CreateSuccess;