import React from 'react';

const UserContext = React.createContext();

export const UserConsumer = UserContext.Consumer;

class UserProvider extends React.Component {
    state = {
        email: "",
        clientId: "",
        updateUser: updatedUser =>
            this.updateUser(updatedUser)
    }

    updateUser = updatedUser => {
        this.setState(() => ({
            email: updatedUser.email,
            clientId: updatedUser.clientId
        }))
    }

    render () {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export default UserProvider
