import React from 'react';
import './Login.css';
import axios from 'axios';
import {Form, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {UserConsumer} from "./auth-context";
import {remoteUrl} from "../props/Props";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {email: '', password: '', clientId: '1', authInvalid: null, ip: ""};

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.email || !this.state.password) {
            return;
        }

        axios.defaults.withCredentials = true;
        axios.post(`${remoteUrl}/v1/authenticate`,{email: this.state.email, password: this.state.password}, {withCredentials: true})
            .then(res => {
                this.props.updateUser({clientId: res.data.clientId, email: res.data.email});
                this.setState({clientId: res.data.clientId, authInvalid: false});
                this.props.history.push('/league');
            })
            .catch(function (error) {
                if (error.response && error.response.status === 403) {
                    this.setState({authInvalid: true});
                }
            }.bind(this));
    }
    handleEmailChange(event) {
        event.preventDefault();
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        event.preventDefault();
        this.setState({password: event.target.value})
    }

    render() {
        return (
            <div>
                <div className="login-container">
                    <div className="login-title">
                        <h3>Sign In</h3>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control onChange={this.handleEmailChange} type="email" placeholder="Your address with an @"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control onChange={this.handlePasswordChange} type="password" isInvalid={this.state.authInvalid} placeholder="Secret Passcode"/>
                            <div className="invalid-feedback">Username or password incorrect.</div>
                        </Form.Group>
                        <div className="button-container">
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#007ab8',
                                borderColor: '#007ab8',
                                borderRadius: '0'
                            }} variant="primary" type="submit">Sign In</Button>
                        </div>
                    </Form>
                </div>
                <div className="ca-container">
                    <Link to="/register">Register your Team</Link>
                </div>
            </div>
        );
    }
}

const ConnectedLogin = props => (
    <UserConsumer>
        {({email, ip, clientId, updateUser}) => (
            <Login
                {...props}
                email={email}
                ip={ip}
                clientId={clientId}
                updateUser={updateUser}
                />
        )}
    </UserConsumer>
)

export default ConnectedLogin;