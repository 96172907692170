import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, Link
} from "react-router-dom";
import ConnectedLogin from './login/Login.js';
import CreateAccount from './account/CreateAccount';
import CreateSuccess from "./account/CreateSuccess";
import UserProvider from "./login/auth-context";
import ConnectedLeagueHome from "./league/LeagueHome";
import {Navbar, NavLink} from "react-bootstrap";
import ConnectedPick from "./pick/Pick";
import Results from "./results/Results";

function App() {
    return (
        <div className="router-container-fill">
            <Router>
                <div className="banner">
                    <div className="title">
                        <h2>B1G Survivor League</h2>
                    </div>
                </div>
                <div className="navbar-container">
                    <Navbar>
                        <NavLink as={Link} bsPrefix="navbar" to="/league">League Home</NavLink>
                        <NavLink as={Link} bsPrefix="navbar" to="/picks">Weekly Picks</NavLink>
                        <NavLink as={Link} bsPrefix="navbar" to="/results">Results</NavLink>
                    </Navbar>
                </div>
                <div className="router-container-row">
                    <div className="router-container-column">
                        <UserProvider>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/login"/>
                                </Route>
                                <Route path="/login" component={ConnectedLogin}/>
                                <Route path="/register" component={CreateAccount}/>
                                <Route path="/create-success" component={CreateSuccess}/>
                                <Route path="/league" component={ConnectedLeagueHome}/>
                                <Route path="/picks" component={ConnectedPick}/>
                                <Route path="/results" component={Results}/>
                            </Switch>
                        </UserProvider>
                    </div>
                </div>
            </Router>
        </div>
    );
}

export default App;
