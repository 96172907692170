import React, {useState} from 'react';
import './Pick.css';
import axios from 'axios';
import {Alert, Dropdown, DropdownButton, Table, Button} from 'react-bootstrap';
import {LockFill, TrashFill} from "react-bootstrap-icons";
import {UserConsumer} from "../login/auth-context";
import {remoteUrl} from "../props/Props";
import Responsive from "react-responsive-decorator";

class Pick extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weekOneMatchups: [],
            weekTwoMatchups: [],
            weekThreeMatchups: [],
            weekFourMatchups: [],
            weekFiveMatchups: [],
            weekSixMatchups: [],
            weekSevenMatchups: [],
            weekEightMatchups: [],
            weekNineMatchups: [],
            weekTenMatchups: [],
            weekElevenMatchups: [],
            weekTwelveMatchups: [],
            weekThirteenMatchups: [],
            weekFourteenMatchups: [],
            picks: [],
            showAlert: false,
            alertMessage: "Team was already used.",
            weekOneLocked: false,
            weekTwoLocked: false,
            weekThreeLocked: false,
            weekFourLocked: false,
            weekFiveLocked: false,
            weekSixLocked: false,
            weekSevenLocked: false,
            weekEightLocked: false,
            weekNineLocked: false,
            weekTenLocked: false,
            weekElevenLocked: false,
            weekTwelveLocked: false,
            weekThirteenLocked: false,
            weekFourteenLocked: false,
            isMobile: false
        };
        this.loadMatchups();
        this.loadPicks();
    }

    componentDidMount() {
        this.props.media({minWidth: 875}, () => {
            this.setState({isMobile: false});
        });
        this.props.media({maxWidth: 875}, () => {
            this.setState({isMobile: true});
        });
    }

    loadMatchups() {
        axios.get(`${remoteUrl}/v1/league/matchups/1`, {withCredentials: true})
            .then(res => {
                this.setState({weekOneMatchups: res.data});
                if (this.state.weekOneMatchups.length == null || this.state.weekOneMatchups.length === 0) {
                    this.setState({weekOneLocked: true});
                }
                else {
                    this.setState({weekOneLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/2`, {withCredentials: true})
            .then(res => {
                this.setState({weekTwoMatchups: res.data});
                if (this.state.weekTwoMatchups.length == null || this.state.weekTwoMatchups.length === 0) {
                    this.setState({weekTwoLocked: true});
                }
                else {
                    this.setState({weekTwoLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/3`, {withCredentials: true})
            .then(res => {
                this.setState({weekThreeMatchups: res.data});
                if (this.state.weekThreeMatchups.length == null || this.state.weekThreeMatchups.length === 0) {
                    this.setState({weekThreeLocked: true});
                }
                else {
                    this.setState({weekThreeLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/4`, {withCredentials: true})
            .then(res => {
                this.setState({weekFourMatchups: res.data});
                if (this.state.weekFourMatchups.length == null || this.state.weekFourMatchups.length === 0) {
                    this.setState({weekFourLocked: true});
                }
                else {
                    this.setState({weekFourLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/5`, {withCredentials: true})
            .then(res => {
                this.setState({weekFiveMatchups: res.data});
                if (this.state.weekFiveMatchups.length == null || this.state.weekFiveMatchups.length === 0) {
                    this.setState({weekFiveLocked: true});
                }
                else {
                    this.setState({weekFiveLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/6`, {withCredentials: true})
            .then(res => {
                this.setState({weekSixMatchups: res.data});
                if (this.state.weekSixMatchups.length == null || this.state.weekSixMatchups.length === 0) {
                    this.setState({weekSixLocked: true});
                }
                else {
                    this.setState({weekSixLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/7`, {withCredentials: true})
            .then(res => {
                this.setState({weekSevenMatchups: res.data});
                if (this.state.weekSevenMatchups.length == null || this.state.weekSevenMatchups.length === 0) {
                    this.setState({weekSevenLocked: true});
                }
                else {
                    this.setState({weekSevenLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/8`, {withCredentials: true})
            .then(res => {
                this.setState({weekEightMatchups: res.data});
                if (this.state.weekEightMatchups.length == null || this.state.weekEightMatchups.length === 0) {
                    this.setState({weekEightLocked: true});
                }
                else {
                    this.setState({weekEightLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/9`, {withCredentials: true})
            .then(res => {
                this.setState({weekNineMatchups: res.data});
                if (this.state.weekNineMatchups.length == null || this.state.weekNineMatchups.length === 0) {
                    this.setState({weekNineLocked: true});
                }
                else {
                    this.setState({weekNineLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/10`, {withCredentials: true})
            .then(res => {
                this.setState({weekTenMatchups: res.data});
                if (this.state.weekTenMatchups.length == null || this.state.weekTenMatchups.length === 0) {
                    this.setState({weekTenLocked: true});
                }
                else {
                    this.setState({weekTenLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/11`, {withCredentials: true})
            .then(res => {
                this.setState({weekElevenMatchups: res.data});
                if (this.state.weekElevenMatchups.length == null || this.state.weekElevenMatchups.length === 0) {
                    this.setState({weekElevenLocked: true});
                }
                else {
                    this.setState({weekElevenLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/12`, {withCredentials: true})
            .then(res => {
                this.setState({weekTwelveMatchups: res.data});
                if (this.state.weekTwelveMatchups.length == null || this.state.weekTwelveMatchups.length === 0) {
                    this.setState({weekTwelveLocked: true});
                }
                else {
                    this.setState({weekTwelveLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/13`, {withCredentials: true})
            .then(res => {
                this.setState({weekThirteenMatchups: res.data});
                if (this.state.weekThirteenMatchups.length == null || this.state.weekThirteenMatchups.length === 0) {
                    this.setState({weekThirteenLocked: true});
                }
                else {
                    this.setState({weekThirteenLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
        axios.get(`${remoteUrl}/v1/league/matchups/14`, {withCredentials: true})
            .then(res => {
                this.setState({weekFourteenMatchups: res.data});
                if (this.state.weekFourteenMatchups.length == null || this.state.weekFourteenMatchups.length === 0) {
                    this.setState({weekFourteenLocked: true});
                }
                else {
                    this.setState({weekFourteenLocked: false});
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
    }

    makeSelection(matchups, teamId) {
        let matchupId = -1;
        matchups.forEach(m => {
            if (m.homeTeam.id == teamId || m.awayTeam.id == teamId) {
                matchupId = m.id;
            }
        });
        axios.post(`${remoteUrl}/v1/league/picks`, {teamId: teamId, matchupId: matchupId})
            .then(res => {
                this.setState({weekOnePick: res.data});
                this.loadPicks();
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
                else if (error.response && error.response.status === 409) {
                    this.setState({showAlert: true, alertMessage: "Team was already used."});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                else if (error.response && error.response.status === 423) {
                    this.setState({showAlert: true, alertMessage: "This week is locked, the game for your current pick has already started."});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                else if (error.response && error.response.status === 405) {
                    this.setState({showAlert: true, alertMessage: "You have not survived and can no longer make picks."});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
            }.bind(this));
    }

    loadPicks() {
        axios.get(`${remoteUrl}/v1/league/picks`, {withCredentials: true})
            .then(res => {this.setState({picks: res.data})})
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
    }

    makeSelectionOne(selection) {
        this.makeSelection(this.state.weekOneMatchups, selection);
    }

    makeSelectionTwo(selection) {
        this.makeSelection(this.state.weekTwoMatchups, selection);
    }

    makeSelectionThree(selection) {
        this.makeSelection(this.state.weekThreeMatchups, selection);
    }

    makeSelectionFour(selection) {
        this.makeSelection(this.state.weekFourMatchups, selection);
    }

    makeSelectionFive(selection) {
        this.makeSelection(this.state.weekFiveMatchups, selection);
    }

    makeSelectionSix(selection) {
        this.makeSelection(this.state.weekSixMatchups, selection);
    }

    makeSelectionSeven(selection) {
        this.makeSelection(this.state.weekSevenMatchups, selection);
    }

    makeSelectionEight(selection) {
        this.makeSelection(this.state.weekEightMatchups, selection);
    }

    makeSelectionNine(selection) {
        this.makeSelection(this.state.weekNineMatchups, selection);
    }

    makeSelectionTen(selection) {
        this.makeSelection(this.state.weekTenMatchups, selection);
    }

    makeSelectionEleven(selection) {
        this.makeSelection(this.state.weekElevenMatchups, selection);
    }

    makeSelectionTwelve(selection) {
        this.makeSelection(this.state.weekTwelveMatchups, selection);
    }

    makeSelectionThirteen(selection) {
        this.makeSelection(this.state.weekThirteenMatchups, selection);
    }

    makeSelectionFourteen(selection) {
        this.makeSelection(this.state.weekFourteenMatchups, selection);
    }

    clearPick(matchupId) {
        axios.post(`${remoteUrl}/v1/league/clear-pick`, {matchupId: matchupId})
            .then(res => {
                this.setState({weekOnePick: res.data});
                this.loadPicks();
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
    }

    render() {
        const picks = this.state.picks.map(pick =>
            <tr>
               <td>{pick.matchup.week.id}</td>
               <td>{pick.team.teamName} over{pick.team.teamName === pick.matchup.awayTeam.teamName ? ' (at) ' + pick.matchup.homeTeam.teamName : ' ' + pick.matchup.awayTeam.teamName}</td>
               <td>{!pick.locked && (<Button onClick={() => this.clearPick(pick.matchup.id)}><TrashFill/></Button>)}</td>
            </tr>
        );

        const weekOneOptions = this.state.weekOneMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekTwoOptions = this.state.weekTwoMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekThreeOptions = this.state.weekThreeMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekFourOptions = this.state.weekFourMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekFiveOptions = this.state.weekFiveMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekSixOptions= this.state.weekSixMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekSevenOptions = this.state.weekSevenMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekEightOptions = this.state.weekEightMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekNineOptions = this.state.weekNineMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekTenOptions = this.state.weekTenMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekElevenOptions = this.state.weekElevenMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekTwelveOptions = this.state.weekTwelveMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekThirteenOptions = this.state.weekThirteenMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const weekFourteenOptions = this.state.weekFourteenMatchups.map(matchup =>
            <div>
                <Dropdown.Item eventKey={matchup.homeTeam.id} disabled={matchup.homeTeam.id >= 100000}>{matchup.homeTeam.teamName} over {matchup.awayTeam.teamName}</Dropdown.Item>
                <Dropdown.Item eventKey={matchup.awayTeam.id} disabled={matchup.awayTeam.id >= 100000}>{matchup.awayTeam.teamName} over (at) {matchup.homeTeam.teamName}</Dropdown.Item>
                <Dropdown.Divider/>
            </div>
        );

        const rowClass = !this.state.isMobile ? "pick-row" : "";

        return (
            <div>
                <Alert variant="danger" show={this.state.showAlert} onClose={() => this.setState({showAlert: false})} dismissible>
                    <Alert.Heading>Error! That pick did not save.</Alert.Heading>
                    <p>
                        {this.state.alertMessage}
                    </p>
                </Alert>
                <div className={rowClass}>
                    <div className="pick-container">
                        <h3 className="pick-header">Choose Wisely</h3>
                        <div className="week-row">
                            <DropdownButton title="Week One" disabled={this.state.weekOneLocked} onSelect={this.makeSelectionOne.bind(this)}>
                                {weekOneOptions}
                            </DropdownButton>
                            {this.state.weekOneLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Two" disabled={this.state.weekTwoLocked} onSelect={this.makeSelectionTwo.bind(this)}>
                                {weekTwoOptions}
                            </DropdownButton>
                            {this.state.weekTwoLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Three" disabled={this.state.weekThreeLocked} onSelect={this.makeSelectionThree.bind(this)}>
                                {weekThreeOptions}
                            </DropdownButton>
                            {this.state.weekThreeLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Four" disabled={this.state.weekFourLocked} onSelect={this.makeSelectionFour.bind(this)}>
                                {weekFourOptions}
                            </DropdownButton>
                            {this.state.weekFourLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Five" disabled={this.state.weekFiveLocked} onSelect={this.makeSelectionFive.bind(this)}>
                                {weekFiveOptions}
                            </DropdownButton>
                            {this.state.weekFiveLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Six" disabled={this.state.weekSixLocked} onSelect={this.makeSelectionSix.bind(this)}>
                                {weekSixOptions}
                            </DropdownButton>
                            {this.state.weekSixLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Seven" disabled={this.state.weekSevenLocked} onSelect={this.makeSelectionSeven.bind(this)}>
                                {weekSevenOptions}
                            </DropdownButton>
                            {this.state.weekSevenLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Eight" disabled={this.state.weekEightLocked} onSelect={this.makeSelectionEight.bind(this)}>
                                {weekEightOptions}
                            </DropdownButton>
                            {this.state.weekEightLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Nine" disabled={this.state.weekNineLocked} onSelect={this.makeSelectionNine.bind(this)}>
                                {weekNineOptions}
                            </DropdownButton>
                            {this.state.weekNineLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Ten" disabled={this.state.weekTenLocked} onSelect={this.makeSelectionTen.bind(this)}>
                                {weekTenOptions}
                            </DropdownButton>
                            {this.state.weekTenLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Eleven" disabled={this.state.weekElevenLocked} onSelect={this.makeSelectionEleven.bind(this)}>
                                {weekElevenOptions}
                            </DropdownButton>
                            {this.state.weekElevenLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Twelve" disabled={this.state.weekTwelveLocked} onSelect={this.makeSelectionTwelve.bind(this)}>
                                {weekTwelveOptions}
                            </DropdownButton>
                            {this.state.weekTwelveLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Thirteen" disabled={this.state.weekThirteenLocked} onSelect={this.makeSelectionThirteen.bind(this)}>
                                {weekThirteenOptions}
                            </DropdownButton>
                            {this.state.weekThirteenLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="week-row">
                            <DropdownButton title="Week Fourteen" disabled={this.state.weekFourteenLocked} onSelect={this.makeSelectionFourteen.bind(this)}>
                                {weekFourteenOptions}
                            </DropdownButton>
                            {this.state.weekFourteenLocked && <LockFill color="black" size={20}/>}
                        </div>
                        <div className="picks-saved">Top choice of each game is the home team.</div>
                    </div>
                    <div className="pick-container">
                        <h3 className="pick-header">Look at dem picks!</h3>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Week</th>
                                    <th>Team</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {picks}
                            </tbody>
                        </Table>
                        <div className="picks-saved">Picks appearing here are saved.</div>
                    </div>
                </div>
            </div>
        );
    }
}

const ConnectedPick = props => (
    <UserConsumer>
        {({email, ip, clientId, updateUser}) => (
            <Pick
                {...props}
                email={email}
                ip={ip}
                clientId={clientId}
                updateUser={updateUser}
            />
        )}
    </UserConsumer>
)

export default Responsive(ConnectedPick);