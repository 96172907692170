import React from "react";
import axios from "axios";
import {remoteUrl} from "../props/Props";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './Results.css';
import ToggleButton from "react-bootstrap/ToggleButton";

class Results extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lockedPicks: [],
            hideDead: true,
        }

        this.toggleHideDead = this.toggleHideDead.bind(this);
    }

    componentDidMount() {
        this.loadPicks();
    }

    loadPicks() {
        axios.get(`${remoteUrl}/v1/league/locked-picks`, {withCredentials: true})
            .then(res => {this.setState({lockedPicks: res.data})})
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
    }

    toggleHideDead() {
        this.setState({hideDead: !this.state.hideDead});
        this.loadPicks();
    }

    render() {

        const rows =
            this.state.hideDead ?
                this.state.lockedPicks.filter(result => result.dead === false).map(result =>
                    <Tr>
                        <Td>{result.teamName}
                            <div>{result.firstName} {result.lastName}</div>
                        </Td>
                        {result.picks.map(pick => {
                            return (
                                <Td>{pick}</Td>
                            )
                        })}
                    </Tr>
                )
                :
                this.state.lockedPicks.map(result =>
                    <Tr>
                        <Td>{result.teamName}
                            <div>{result.firstName} {result.lastName}</div>
                        </Td>
                        {result.picks.map(pick => {
                            return (
                                <Td>{pick}</Td>
                            )
                        })}
                    </Tr>
                );

        return (
            <div>
                <div className="deadCheckClass">
                    <ToggleButton
                        type="checkbox"
                        style={{color: "white", backgroundColor: "black"}}
                        checked={this.state.hideDead}
                        onClick={this.toggleHideDead}
                    >
                        Hide The Losers
                    </ToggleButton>
                </div>
                <div className="results-container">
                    <div className="results-header">
                        <h2>Results</h2>
                    </div>
                    <Table  striped>
                        <Thead>
                        <Tr>
                            <Th>Team Name</Th>
                            <Th>Week 1</Th>
                            <Th>Week 2</Th>
                            <Th>Week 3</Th>
                            <Th>Week 4</Th>
                            <Th>Week 5</Th>
                            <Th>Week 6</Th>
                            <Th>Week 7</Th>
                            <Th>Week 8</Th>
                            <Th>Week 9</Th>
                            <Th>Week 10</Th>
                            <Th>Week 11</Th>
                            <Th>Week 12</Th>
                            <Th>Week 13</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        {rows}
                        </Tbody>
                    </Table>
                    <div className="results-showed">Results are shown for each week after that week is locked.</div>
                </div>
            </div>
        );
    }
}

export default Results;