import React from 'react';
import './LeagueHome.css';
import axios from "axios";
import {UserConsumer} from "../login/auth-context";
import {ListGroup} from "react-bootstrap";
import {Table} from "react-bootstrap";
import {remoteUrl} from "../props/Props";

class LeagueHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            boughtBackTeams: []
        };
        this.loadTeams();
    }

    loadTeams() {
        axios.get(`${remoteUrl}/v1/league/teams?dead=true`, {withCredentials: true})
            .then(res => {this.setState({teams: res.data})})
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));

        axios.get(`${remoteUrl}/v1/league/teams?dead=false&boughtBack=true`, {withCredentials: true})
            .then(res => {this.setState({boughtBackTeams: res.data})})
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    this.props.history.push('/login');
                }
            }.bind(this));
    }

    render() {
        const rows = this.state.teams.map(row =>
            <tr>
                <td>{row.firstName} {row.lastName}</td>
                <td>{row.teamName}</td>
            </tr>
        );
        const boughtBackRows = this.state.boughtBackTeams.map(row =>
            <tr>
                <td>{row.firstName} {row.lastName}</td>
                <td>{row.teamName}</td>
            </tr>
        );

        return (
            <div>
                <div className="league-home-container">
                    <div>
                        <p>
                            Well that offseason went by rather quick.  Thanks for coming back everyone, here's to another great B1G Season!
                        </p>
                    </div>
                </div>
                <div className="league-teams-container">
                    <h2 className="competition-header">The Memorial</h2>
                    <span>Remembering the fallen teams who fell trap to upsets and horrible picks.</span>
                    <Table striped hover>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </div>
                <div className="league-teams-container">
                    <h2 className="competition-header">Last Chancers</h2>
                    <span>People who threw more money at this game to stay alive.</span>
                    <Table striped hover>
                        <tbody>
                        {boughtBackRows}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

const ConnectedLeagueHome = props => (
    <UserConsumer>
        {({email, ip, clientId, updateUser}) => (
            <LeagueHome
                {...props}
                email={email}
                ip={ip}
                clientId={clientId}
                updateUser={updateUser}
            />
        )}
    </UserConsumer>
)

export default ConnectedLeagueHome;