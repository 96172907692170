import React from 'react';
import './CreateAccount.css';
import {Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import {remoteUrl} from "../props/Props";

class CreateAccount extends React.Component {

    constructor(props) {
        super(props);

        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.email = React.createRef();
        this.password = React.createRef();
        this.passwordConfirm = React.createRef();
        this.teamName = React.createRef();

        this.passwordConfirmValid = null;

        this.state = {
            emailInvalid: null,
            passwordsMismatch: null,
            submitButtonDisabled: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({submitButtonDisabled: true})

        if (this.state.passwordsMismatch) {
            this.setState({submitButtonDisabled: false})
            return;
        }

        axios.defaults.headers.post['X-FORWARDED-FOR'] = '127.0.0.1';
        axios.post(`${remoteUrl}/v1/user/create`, {email: this.email.current.value,
            password: this.password.current.value,
            firstName: this.firstName.current.value,
            lastName: this.lastName.current.value,
            teamName: this.teamName.current.value})
            .then(this.props.history.push('/create-success'))
            .finally(this.setState({submitButtonDisabled: false}));
    }

    emailChange(event) {
        event.preventDefault();

        axios.get(`${remoteUrl}/v1/user/${event.target.value}/exists`)
            .then(this.setState({emailInvalid: false}))
            .catch(function (error) {
               if (error.response && error.response.status === 409) {
                    this.setState({emailInvalid: true});
               }
            }.bind(this));
    }

    confirmPasswordChange(event) {
        event.preventDefault();

        if (this.password.current.value !== this.passwordConfirm.current.value) {
            this.setState({passwordsMismatch: true});
        }
        else {
            this.setState({passwordsMismatch: false});
        }
    }

    render() {
        return (
            <div>
                <div className="account-container">
                    <div className="account-title">
                        <h3>Register Team</h3>
                    </div>
                    <div className="create-form-container">
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>First Name</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control ref={this.firstName} required
                                                  placeholder="maybe its John or Larry"></Form.Control>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>Last Name</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control ref={this.lastName} required
                                                  placeholder="Schneider is a popular one"></Form.Control>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>Team Name</Form.Label>
                                </Col>
                                <Col className="">
                                    <Form.Control ref={this.teamName} required
                                                  placeholder="don't mess this up"></Form.Control>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>Email</Form.Label>
                                </Col>
                                <Col className="col-container">
                                    <Form.Control ref={this.email} onBlur={this.emailChange.bind(this)} type="email" required
                                                  isInvalid={this.state.emailInvalid} placeholder="for spam"></Form.Control>
                                    <div className="invalid-feedback">Email already exists</div>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>Password</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control ref={this.password} type="password" required  isInvalid={this.state.passwordsMismatch}
                                                  placeholder="do your best"></Form.Control>
                                    <div className="invalid-feedback">Passwords don't match.</div>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <Col className="col-4 col-container">
                                    <Form.Label>Confirm Password</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control ref={this.passwordConfirm} type="password" required  isInvalid={this.state.passwordsMismatch}
                                                  placeholder="almost there" onBlur={this.confirmPasswordChange.bind(this)}></Form.Control>
                                    <div className="invalid-feedback">Passwords dont match</div>
                                </Col>
                            </Row>
                            <div className="button-container">
                                <Button style={{
                                    color: 'white',
                                    backgroundColor: '#007ab8',
                                    borderColor: '#007ab8',
                                    borderRadius: '0'
                                }} variant="primary" type="submit"
                                disabled={this.state.submitButtonDisabled}>Register</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

    export default CreateAccount;